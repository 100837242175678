<template functional>
    <svg
        height="16px"
        viewBox="0 0 16 16"
        width="16px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g transform="translate(-20, -17)">
                <g transform="translate(20, 16)">
                    <g transform="translate(0, 1)">
                        <rect
                            id="Rectangle"
                            height="16"
                            width="16"
                            x="0"
                            y="0"
                        />
                        <g
                            fill="#3C4B64"
                            fill-rule="nonzero"
                            transform="translate(1.3913, 1.3913)"
                        >
                            <rect
                                height="4.86956522"
                                width="1.04347826"
                                x="5.91304348"
                                y="4.52173913"
                            />
                            <rect
                                height="1.04347826"
                                width="1.04347826"
                                x="5.91304348"
                                y="10.0869565"
                            />
                            <path
                                d="M7.10473333,0 L6.11265797,0 L0,11.8178348 L0,13.2173913 L13.2173913,13.2173913 L13.2173913,11.8178348 L7.10473333,0 Z M12.3362319,12.3362319 L0.88115942,12.3362319 L0.88115942,12.0322043 L6.60869565,0.958976812 L12.3362319,12.0322043 L12.3362319,12.3362319 Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'CorrectiveMaintenance',
};
</script>
